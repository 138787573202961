<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isHistory"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black-trasparent transition-opacity"
          aria-hidden="true"
          @click.prevent="() => $emit('closeModalHistory')"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="inline-block align-middle shadow-xl transform transition-all"
          >
            <div class="modal-size flex flex-col items-center justify-center">
              <div
                class="w-full relative bg-agrogo-yellow-2 flex items-center justify-center py-5"
              >
                <p class="font-nexa font-black text-agrogo-green-2 text-2xl">
                  Histórico <span class="font-normal"> de Pujas</span>
                </p>
                <div class="absolute top-2 right-2 md:top-2.5 md:right-2.5">
                  <button
                    class="btn-close"
                    @click.prevent="() => $emit('closeModalHistory')"
                  >
                    <span class="icon-x"></span>
                  </button>
                </div>
              </div>
              <div class="grid grid-cols-4 bg-agrogo-green-2 w-full min-h-10">
                <div class="flex items-center justify-center">
                  <span
                    class="font-dinpro font-bold text-base text-white md:text-lg"
                    >Fecha</span
                  >
                </div>
                <div class="flex items-center justify-center">
                  <span
                    class="font-dinpro font-bold text-base text-white md:text-lg"
                    >Hora</span
                  >
                </div>
                <div class="flex items-center justify-center">
                  <span
                    class="font-dinpro font-bold text-base text-white md:text-lg"
                    >#Paleta</span
                  >
                </div>
                <div class="flex items-center justify-center">
                  <span
                    class="font-dinpro font-bold text-base text-white md:text-lg"
                    >Precio Actual</span
                  >
                </div>
              </div>
              <div
                class="modal-size grid grid-cols-1 height-contain-history overflow-y-auto"
              >
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-white">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >8:00:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#02</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 600.000.000</span
                    >
                  </div>
                </div>
                <div class="grid grid-cols-4 w-full min-h-10 bg-agrogo-gray-2">
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >10/09/2022</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >7:10:34 pm</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >#09</span
                    >
                  </div>
                  <div class="flex items-center justify-center">
                    <span
                      class="font-dinpro font-medium text-sm md:text-base text-agrogo-black-1"
                      >$ 590.000.000</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isHistory: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.modal-size {
  min-width: 346px;
}
@media (min-width: 768px) {
  .modal-size {
    width: 576px;
  }
}
.min-h-10 {
  min-height: 41px;
  height: 100%;
}
.height-contain-history {
  max-height: 462px;
}
</style>
